import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDpgGyTKNt0PR5fWzh4evpRUqqyG_xXhgk",
  authDomain: "fatma-baci.firebaseapp.com",
  projectId: "fatma-baci",
  storageBucket: "fatma-baci.appspot.com",
  messagingSenderId: "502729297069",
  appId: "1:502729297069:web:f3f029804a193f40dc4a24",
  measurementId: "G-48C11EHFT1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
