import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import React from "react";
import XIcon from "@mui/icons-material/X";
const styles = {
  footer: {
    // backgroundColor: "#2c3e50",
    color: "#1b1b1b",
    padding: "3rem 1rem",
    marginTop: "2rem",
    backdropFilter: "blur(2px)",
    WebkitBackdropFilter: "blur(2px)",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  section: {
    flex: "1 1 250px",
    margin: "1rem",
  },
  sectionTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#1b1b1b",
  },
  link: {
    color: "#1b1b1b",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",

    marginBottom: "0.5rem",
    transition: "color 0.3s ease",
  },
  socialIcon: {
    width: "24px",
    height: "24px",
    marginRight: "1rem",
  },
  copyright: {
    textAlign: "center",
    marginTop: "2rem",
    paddingTop: "1rem",
    // borderTop: "1px solid #1b1b1b",
  },
};

function Footer() {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Fatma Bacı Turşuları</h3>
          <p>
            1989'dan beri geleneksel lezzetler sunuyoruz. Kalite ve lezzet bizim
            önceliğimizdir.
          </p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Hızlı Erişim</h3>
          <Link to="/" style={styles.link}>
            Ana Sayfa
          </Link>
          <Link to="/products" style={styles.link}>
            Ürünlerimiz
          </Link>
          <Link to="/about" style={styles.link}>
            Hakkımızda
          </Link>
          <Link to="/contact" style={styles.link}>
            İletişim
          </Link>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>İletişim</h3>
          <p>
            Adres: Karşıyaka Mah. Kavakdere Cad. Bağlariçi Sokak No:15
            Keçiören/Ankara
          </p>
          <p>Telefon: +90 (546) 229 94 24</p>
          <p>E-posta: siparis@fatmabaci.com</p>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Bizi Takip Edin</h3>
          <div>
            <a
              href="https://www.facebook.com/fatmabacitr/"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              {/* <img
                src="/facebook-icon.png"
                alt="Facebook"
                style={styles.socialIcon}
              /> */}
              <FacebookIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "0.4rem",
                }}
              />
              Facebook
            </a>
            <a
              href="https://www.instagram.com/fatmabacitr/"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              <InstagramIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "0.4rem",
                }}
              />
              Instagram
            </a>
            <a
              href="https://x.com/fatmabacitr"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              <XIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "0.4rem",
                }}
              />
              Twitter
            </a>
          </div>
        </div>
      </div>
      <div style={styles.copyright}>
        <p>
          &copy; {new Date().getFullYear()} Fatma Bacı Turşuları. Tüm hakları
          saklıdır.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
