import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// Assuming you have these images imported or accessible
import product1 from "../assets/1.png";
import product10 from "../assets/10.png";
import product2 from "../assets/2.png";
import product3 from "../assets/3.png";
import product4 from "../assets/4.png";
import product5 from "../assets/5.png";
import product6 from "../assets/6.png";
import product7 from "../assets/7.png";
import product8 from "../assets/8.png";
import product9 from "../assets/9.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2ecc71",
    },
    secondary: {
      main: "#3498db",
    },
  },
});

const products = [
  {
    name: "Yeşil Domates Turşusu",
    image: product1,
    description:
      "Çıtır çıtır, ekşi lezzetiyle damakları şenlendiren yeşil domates turşusu",
  },
  {
    name: "Acur Turşusu",
    description: "Geleneksel tarifle hazırlanan, çıtır acur turşusu",
    image: product2,
  },
  {
    name: "Pancar Turşusu",
    description:
      "Rengiyle göz alan, tatlı ekşi lezzetiyle vazgeçilmez pancar turşusu",
    image: product3,
  },
  {
    name: "Biberiye Biber",
    description: "Biberiye aromasıyla zenginleştirilmiş, özel biber turşusu",
    image: product4,
  },
  {
    name: "Acı İnce Biber",
    description: "Ateşli lezzet severlere özel, acı ince biber turşusu",
    image: product5,
  },
  {
    name: "Lahana Turşusu",
    description: "Mükemmel fermente edilmiş, probiyotik lahana turşusu",
    image: product6,
  },
  {
    name: "Çubuk Salatalığı Turşusu",
    description: "Geleneksel lezzetiyle vazgeçilmez çubuk salatalık turşusu",
    image: product7,
  },
  {
    name: "Kornişon Salatalık",
    description: "Atıştırmalık olarak ideal, minik kornişon turşuları",
    image: product8,
  },
  {
    name: "Erik Turşusu",
    description: "Alışılmışın dışında, enfes erik turşusu",
    image: product9,
  },
  {
    name: "Fasülye Turşusu",
    description: "Çıtır çıtır, lezzetli fasülye turşusu",
    image: product10,
  },
];

function Products() {
  const [quantities, setQuantities] = useState(
    products.reduce((acc, product) => ({ ...acc, [product.name]: 0 }), {})
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleQuantityChange = (productName, change) => {
    setQuantities((prev) => ({
      ...prev,
      [productName]: Math.max(0, prev[productName] + change),
    }));
  };

  const getTotalItems = () => {
    return Object.values(quantities).reduce(
      (sum, quantity) => sum + quantity,
      0
    );
  };

  const sendOrder = () => {
    const orderItems = Object.entries(quantities)
      .filter(([_, quantity]) => quantity > 0)
      .map(([name, quantity]) => `${name}: ${quantity} kg`)
      .join("\n");

    if (orderItems) {
      const message = encodeURIComponent(
        `Merhaba, aşağıdaki ürünleri sipariş etmek istiyorum:\n\n${orderItems}`
      );
      const whatsappUrl = `https://wa.me/905001234567?text=${message}`; // Replace with your WhatsApp number
      window.open(whatsappUrl, "_blank");
      setDialogOpen(false);
    } else {
      alert("Lütfen en az bir ürün seçin.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 4, marginY: "10vh" }}>
        <Typography variant="h2" component="h1" align="center" gutterBottom>
          Fatma Bacı Turşuları
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
        >
          Geleneksel Lezzetler, Modern Sofralar
        </Typography>

        <Grid container spacing={4}>
          {products.map((product, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    // boxShadow: 3,
                  },
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    // height: 200,
                    objectFit: "cover",
                    // borderRadius: "20%",
                    // opacity: 0.8,
                  }}
                  image={product.image}
                  alt={product.name}
                />
                <CardContent sx={{}}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.name}
                  </Typography>
                  <Typography>{product.description}</Typography>
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    px: 2,
                    pb: 2,
                    bottom: 0,
                    right: 0,
                    // background: "red",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {quantities[product.name] > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        size="small"
                        color="secondary"
                        sx={{
                          width: 40,
                        }}
                        onClick={() => handleQuantityChange(product.name, -1)}
                      >
                        <Remove
                          sx={{
                            color: "#1b1b1b",
                          }}
                        />
                      </Button>

                      <TextField
                        sx={{ width: 60, mx: 1 }}
                        size="small"
                        value={quantities[product.name]}
                        onChange={(e) =>
                          setQuantities((prev) => ({
                            ...prev,
                            [product.name]: Math.max(
                              0,
                              parseInt(e.target.value) || 0
                            ),
                          }))
                        }
                        inputProps={{ min: 0, style: { textAlign: "center" } }}
                      />
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => handleQuantityChange(product.name, 1)}
                      >
                        <Add
                          sx={{
                            color: "#1b1b1b",
                          }}
                        />
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#1b1b1b",
                        color: "#fff",
                      }}
                      endIcon={<ShoppingCartIcon />}
                      onClick={() => handleQuantityChange(product.name, 1)}
                    >
                      Ekle
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        {getTotalItems() > 0 && (
          <Fab
            aria-label="order"
            color="secondary"
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1001,
              backgroundColor: "#1b1b1b",
            }}
            onClick={() => setDialogOpen(true)}
          >
            <ShoppingCartIcon sx={{ color: "#fff" }} />
          </Fab>
        )}

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="order-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="order-dialog-title">Sipariş Özeti</DialogTitle>
          <DialogContent>
            <List>
              {Object.entries(quantities)
                .filter(([_, quantity]) => quantity > 0)
                .map(([name, quantity], index) => (
                  <React.Fragment key={name}>
                    <ListItem>
                      <ListItemText
                        primary={name}
                        secondary={`${quantity} kg`}
                      />
                    </ListItem>
                    {index <
                      Object.entries(quantities).filter(([_, q]) => q > 0)
                        .length -
                        1 && <Divider />}
                  </React.Fragment>
                ))}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                pt: 2,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Typography variant="subtitle1">Toplam</Typography>
              <Typography variant="subtitle1">{getTotalItems()} kg</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                color: "#1b1b1b",
                textTransform: "none",
              }}
              onClick={() => setDialogOpen(false)}
              color="primary"
            >
              İptal
            </Button>
            <Button
              sx={{
                color: "#1b1b1b",
                textTransform: "none",
              }}
              onClick={sendOrder}
            >
              Sipariş Ver
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}

export default Products;
