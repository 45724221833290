import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { keyframes, styled } from "@mui/system";

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import l1 from "../assets/l1.png";
import l2 from "../assets/l2.png";
import l3 from "../assets/l3.png";
import product1 from "../assets/1.png";
import product10 from "../assets/10.png";
import product2 from "../assets/2.png";
import product3 from "../assets/3.png";
import product4 from "../assets/4.png";
import product5 from "../assets/5.png";
import product6 from "../assets/6.png";
import product7 from "../assets/7.png";
import product8 from "../assets/8.png";
import product9 from "../assets/9.png";

const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ProductSlider = styled(Box)({
  display: "flex",
  overflow: "hidden",
  width: "100%",
  position: "fixed",
  bottom: 0,
  left: 0,
  opacity: 0.2,
  zIndex: -1,
});

const ProductSliderInner = styled(Box)({
  display: "flex",
  animation: `${slideAnimation} 50s linear infinite`,
});

const ProductImage = styled("img")({
  width: "100px",
  height: "100px",
  objectFit: "cover",
});

const products = [
  { name: "Yeşil Domates Turşusu", image: product1 },
  { name: "Acur Turşusu", image: product2 },
  { name: "Pancar Turşusu", image: product3 },
  { name: "Biber Turşusu", image: product4 },
  { name: "Salatalık Turşusu", image: product5 },
  { name: "Lahana Turşusu", image: product6 },
  { name: "Karışık Turşu", image: product7 },
  { name: "Havuç Turşusu", image: product8 },
  { name: "Biberiye Turşusu", image: product9 },
  { name: "Kelek Turşusu", image: product10 },
];

const HeroSection = styled(Box)(({ theme }) => ({
  color: "#1b1b1b",
  textAlign: "center",
  padding: theme.spacing(8, 4),
  marginBottom: theme.spacing(4),
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backdropFilter: "blur(2px)",
  WebkitBackdropFilter: "blur(2px)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  fontWeight: "bold",
  background: "linear-gradient(to bottom, #1b1b1b, #4b4b4b)",
  textTransform: "none",
}));

const FeatureIcon = styled("img")({
  width: "120px",
  height: "120px",
  marginBottom: "1rem",
});

// const products = [
//   {
//     name: "Yeşil Domates Turşusu",
//     image: product1,
//     description:
//       "Çıtır çıtır, ekşi lezzetiyle damakları şenlendiren yeşil domates turşusu",
//   },
//   {
//     name: "Acur Turşusu",
//     image: product2,
//     description: "Geleneksel tarifle hazırlanan, çıtır acur turşusu",
//   },
//   {
//     name: "Pancar Turşusu",
//     image: product3,
//     description:
//       "Rengiyle göz alan, tatlı ekşi lezzetiyle vazgeçilmez pancar turşusu",
//   },
// ];

function Home() {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <HeroSection>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "32px",
              md: "48px",
            },
            color: "#1b1b1b",
          }}
          gutterBottom
        >
          Fatma Bacı Turşularına Hoş Geldiniz
        </Typography>
        <Typography
          variant="h5"
          paragraph
          sx={{
            // fontWeight: "bold",
            fontSize: {
              xs: "24px",
              md: "28px",
            },
            marginBottom: {
              xs: "2rem",
              md: "4rem",
            },
            color: "#1b1b1b",
          }}
        >
          Geleneksel Lezzetin Çıtır ve Ekşi Dünyasını Keşfedin
        </Typography>
        <StyledButton
          component={RouterLink}
          to="/products"
          variant="contained"
          color="primary"
          size="large"
        >
          Ürünlerimizi Keşfedin
        </StyledButton>
      </HeroSection>

      <Grid container spacing={4} sx={{ mb: 40 }}>
        {[
          { label: "%100 Doğal", image: l1 },
          { label: "Geleneksel Tarif", image: l2 },
          { label: "Geniş Çeşitlilik", image: l3 },
        ].map((feature, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            sx={{
              backdropFilter: "blur(2px)",
              WebkitBackdropFilter: "blur(2px)",
            }}
          >
            <Box textAlign="center">
              <FeatureIcon src={feature.image} alt={feature} />
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#1b1b1b",
                  fontWeight: "bold",
                }}
              >
                {feature.label}
              </Typography>
              <Typography
                sx={{
                  color: "#1b1b1b",
                  fontSize: {
                    xs: "16px",
                    md: "18px",
                  },
                }}
              >
                {index === 0 && "Taze, yerel malzemelerle üretilmiştir"}
                {index === 1 && "Nesillerdir aktarılan zaman onaylı teknikler"}
                {index === 2 && "Klasik lezzetlerden egzotik tatlara"}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Home;
