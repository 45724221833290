import React, { useState } from "react";

import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const styles = {
  header: {
    // backgroundColor: "transparent",
    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
    backdropFilter: "blur(2px)",
    WebkitBackdropFilter: "blur(2px)",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  logo: {
    height: "60px",
    width: "auto",
  },
  nav: {
    display: "flex",
  },
  navList: {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  navItem: {
    marginLeft: "1.5rem",
  },
  navLink: {
    color: "#333",
    textDecoration: "none",
    fontWeight: 500,
    transition: "color 0.3s ease",
  },
  menuToggle: {
    display: "none",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0.5rem",
  },
  menuIcon: {
    display: "block",
    width: "25px",
    height: "3px",
    backgroundColor: "#333",
    position: "relative",
    transition: "background-color 0.3s ease",
  },
  menuIconBefore: {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#333",
    top: "-8px",
    transition: "all 0.3s ease",
  },
  menuIconAfter: {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#333",
    bottom: "-8px",
    transition: "all 0.3s ease",
  },
};

const mobileStyles = {
  nav: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "none",
  },
  navOpen: {
    display: "block",
  },
  navList: {
    flexDirection: "column",
    padding: "1rem",
  },
  navItem: {
    margin: "0.5rem 0",
  },
  menuToggle: {
    display: "block",
  },
  menuIconActive: {
    backgroundColor: "transparent",
  },
  menuIconBeforeActive: {
    transform: "rotate(45deg)",
    top: 0,
  },
  menuIconAfterActive: {
    transform: "rotate(-45deg)",
    bottom: 0,
  },
};

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navItems = [
    { to: "/", label: "Anasayfa" },
    { to: "/products", label: "Ürünlerimiz" },
    { to: "/about", label: "Hakkımızda" },
    { to: "/contact", label: "İletişim" },
  ];

  return (
    <header style={styles.header}>
      <div style={styles.headerContent}>
        <Link to="/" onClick={closeMenu}>
          <img src={logo} alt="Fatma Bacı Turşuları" style={styles.logo} />
        </Link>
        <nav
          style={{
            ...styles.nav,
            ...(isMobile && mobileStyles.nav),
            ...(isMobile && isMenuOpen && mobileStyles.navOpen),
          }}
        >
          <ul
            style={{
              ...styles.navList,
              ...(isMobile && mobileStyles.navList),
            }}
          >
            {navItems.map((item) => (
              <li
                key={item.to}
                style={{
                  ...styles.navItem,
                  ...(isMobile && mobileStyles.navItem),
                }}
              >
                <Link to={item.to} onClick={closeMenu} style={styles.navLink}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <button
          style={{
            ...styles.menuToggle,
            ...(isMobile && mobileStyles.menuToggle),
          }}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span
            style={{
              ...styles.menuIcon,
              ...(isMenuOpen && mobileStyles.menuIconActive),
            }}
          >
            <span
              style={{
                ...styles.menuIconBefore,
                ...(isMenuOpen && mobileStyles.menuIconBeforeActive),
              }}
            ></span>
            <span
              style={{
                ...styles.menuIconAfter,
                ...(isMenuOpen && mobileStyles.menuIconAfterActive),
              }}
            ></span>
          </span>
        </button>
      </div>
    </header>
  );
}

export default Header;
