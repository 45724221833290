import { Box, Container, Grid, Typography } from "@mui/material";

import React from "react";
import head from "../assets/head.png";

const styles = {
  root: {
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
    padding: { xs: "2rem 1rem", md: "4rem 2rem" },
    marginY: "10vh",
  },
  section: {
    marginBottom: "3rem",
  },
  sectionTitle: {
    fontSize: { xs: "1.5rem", md: "2rem" },
    marginBottom: "1rem",
    color: "#2c3e50",
  },
  image: {
    width: "100%",
    maxWidth: "300px",
    borderRadius: "8px",
    opacity: 0.2,
    marginBottom: { xs: "1rem", md: 0 },
  },
  timeline: {
    position: "relative",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "1rem 0",
  },
  timelineItem: {
    padding: "1rem 1rem 1rem 2rem",
    borderLeft: "2px solid #2c3e50",
    position: "relative",
    marginBottom: "1rem",
  },
  timelineDate: {
    fontWeight: "bold",
    color: "#2c3e50",
    marginBottom: "0.5rem",
  },
  valueItem: {
    textAlign: "center",
    margin: "1rem",
  },
};

function About() {
  return (
    <Container maxWidth="lg">
      <Box sx={styles.root}>
        <Box component="section" sx={styles.section}>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: "2rem", md: "3rem" }, marginBottom: "1rem" }}
          >
            Fatma Bacı Turşuları Hikayesi
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1rem", md: "1.2rem" },
              marginBottom: "2rem",
            }}
          >
            1989'dan Beri Geleneksel Lezzetler
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography paragraph>
                Fatma Bacı Turşuları, 1989 yılında Ankara'da küçük bir mutfakta
                başlayan yolculuğuna bugün Türkiye'nin en sevilen turşu
                markalarından biri olarak devam ediyor. Kurucumuz Fatma
                Teyze'nin tutkusu ve geleneksel tarifleri, bugün üçüncü nesil
                aile üyeleri tarafından yaşatılmaya devam ediyor.
              </Typography>
              <Typography paragraph>
                Her kavanozda, yılların birikimi olan ustalık ve sevgiyi
                bulacaksınız. Turşularımızı yaparken kullandığımız taze
                sebzeler, özenle seçilmiş baharatlar ve sabırla bekletme
                sürecimiz, lezzetimizin sırrını oluşturuyor.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                component="img"
                src={head}
                alt="Fatma Bacı"
                sx={styles.image}
              />
            </Grid>
          </Grid>
        </Box>

        <Box component="section" sx={styles.section}>
          <Typography variant="h2" sx={styles.sectionTitle}>
            Tarihçemiz
          </Typography>
          <Box sx={styles.timeline}>
            {[
              {
                date: "1872",
                text: "Fatma Teyze, evinin mutfağında ilk turşularını kurmaya başladı.",
              },
              {
                date: "1989",
                text: "Artan talep üzerine ilk dükkânımızı açtık.",
              },
              {
                date: "1990",
                text: "Türkiye genelinde dağıtıma başladık ve ürün çeşitlerimizi artırdık.",
              },
              {
                date: "2010",
                text: "Modern üretim tesisimizi kurduk, geleneksel lezzetimizi koruyarak üretim kapasitemizi artırdık.",
              },
              {
                date: "Bugün",
                text: "Türkiye'nin dört bir yanında milyonlarca evde sofralarını süslüyoruz.",
              },
            ].map((item, index) => (
              <Box key={index} sx={styles.timelineItem}>
                <Typography component="div" sx={styles.timelineDate}>
                  {item.date}
                </Typography>
                <Typography>{item.text}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box component="section" sx={styles.section}>
          <Typography variant="h2" sx={styles.sectionTitle}>
            Değerlerimiz
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                title: "Kalite",
                text: "En taze malzemeler ve titiz üretim süreçleriyle en kaliteli ürünleri sunuyoruz.",
              },
              {
                title: "Gelenek",
                text: "Geleneksel tarifleri ve yöntemleri koruyarak, nesiller boyu aktarılan lezzetleri yaşatıyoruz.",
              },
              {
                title: "Yenilikçilik",
                text: "Geleneksel lezzetleri korurken, modern üretim teknikleriyle verimliliği artırıyoruz.",
              },
              {
                title: "Müşteri Memnuniyeti",
                text: "Müşterilerimizin memnuniyeti bizim için her şeyden önemlidir.",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box sx={styles.valueItem}>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography>{item.text}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box component="section" sx={styles.section}>
          <Typography variant="h2" sx={styles.sectionTitle}>
            Üretim Sürecimiz
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography paragraph>
                Turşularımızın üretim süreci, tarladan sofraya kadar her aşamada
                titizlikle kontrol edilir:
              </Typography>
              <ol>
                <li>En taze sebzeleri özenle seçiyoruz.</li>
                <li>
                  Geleneksel tariflerimize göre hazırladığımız salamurayı
                  hazırlıyoruz.
                </li>
                <li>Sebzeleri özel fermentasyon tanklarımızda bekletiyoruz.</li>
                <li>Hijyenik koşullarda paketleme yapıyoruz.</li>
                <li>
                  Sevkiyat öncesi son kalite kontrolünü gerçekleştiriyoruz.
                </li>
              </ol>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default About;
