import React, { useCallback, useEffect, useState } from "react";

// Import illustrations
const illustrations = Array.from({ length: 20 }, (_, i) =>
  require(`../assets/illustrations/${i + 1}.png`)
);

const styles = {
  backgroundContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    zIndex: -1,
    overflow: "hidden",
  },
  illustration: {
    position: "absolute",
    width: "80px",
    height: "auto",
    transition: "opacity 2s ease-in-out",
  },
};

function AnimatedBackground() {
  const [illustrationStates, setIllustrationStates] = useState([]);

  const getRandomEdgePosition = useCallback(() => {
    const edge = Math.floor(Math.random() * 4);
    const pos = Math.random() * 100;
    switch (edge) {
      case 0:
        return { top: "10px", left: `${pos}%` };
      case 1:
        return { top: `${pos}%`, right: "10px" };
      case 2:
        return { bottom: "10px", left: `${pos}%` };
      default:
        return { top: `${pos}%`, left: "10px" };
    }
  }, []);

  const toggleRandomIllustration = useCallback(() => {
    setIllustrationStates((prevStates) => {
      const newStates = [...prevStates];
      const visibleCount = newStates.filter((state) => state.isVisible).length;

      if (visibleCount <= 5) {
        // Show a hidden illustration
        const hiddenIndices = newStates
          .map((state, index) => ({ ...state, index }))
          .filter((state) => !state.isVisible)
          .map((state) => state.index);

        if (hiddenIndices.length > 0) {
          const randomIndex =
            hiddenIndices[Math.floor(Math.random() * hiddenIndices.length)];
          newStates[randomIndex] = {
            ...newStates[randomIndex],
            isVisible: true,
            ...getRandomEdgePosition(),
          };
        }
      } else if (visibleCount >= 15) {
        // Hide a visible illustration
        const visibleIndices = newStates
          .map((state, index) => ({ ...state, index }))
          .filter((state) => state.isVisible)
          .map((state) => state.index);

        if (visibleIndices.length > 0) {
          const randomIndex =
            visibleIndices[Math.floor(Math.random() * visibleIndices.length)];
          newStates[randomIndex] = {
            ...newStates[randomIndex],
            isVisible: false,
          };
        }
      } else {
        // Randomly show or hide
        const randomIndex = Math.floor(Math.random() * newStates.length);
        newStates[randomIndex] = {
          ...newStates[randomIndex],
          isVisible: !newStates[randomIndex].isVisible,
          ...(newStates[randomIndex].isVisible ? {} : getRandomEdgePosition()),
        };
      }

      return newStates;
    });
  }, [getRandomEdgePosition]);

  useEffect(() => {
    // Initialize illustration states with 10 random visible
    const initialStates = illustrations.map((_, index) => ({
      ...getRandomEdgePosition(),
      isVisible: index < 5,
    }));
    // Shuffle the array to randomize which 10 are visible
    for (let i = initialStates.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [initialStates[i], initialStates[j]] = [
        initialStates[j],
        initialStates[i],
      ];
    }
    setIllustrationStates(initialStates);

    // Start animation loop
    const interval = setInterval(toggleRandomIllustration, 2000);

    return () => clearInterval(interval);
  }, [getRandomEdgePosition, toggleRandomIllustration]);

  return (
    <div style={styles.backgroundContainer}>
      {illustrationStates.map((state, index) => (
        <img
          key={index}
          src={illustrations[index]}
          alt={`Illustration ${index + 1}`}
          style={{
            ...styles.illustration,
            ...state,
            opacity: state.isVisible ? 0.5 : 0,
          }}
        />
      ))}
    </div>
  );
}

export default AnimatedBackground;
