import React, { useState } from "react";
import { addDoc, collection, getFirestore } from "firebase/firestore";

import { db } from "../firebase";

const styles = {
  contact: {
    maxWidth: "1200px",
    margin: "10vh auto",
    padding: "2rem 1rem",
  },
  header: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  title: {
    fontSize: "2.5rem",
    color: "#2c3e50",
    marginBottom: "1rem",
  },
  subtitle: {
    fontSize: "1.2rem",
    color: "#7f8c8d",
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem",
  },
  formSection: {
    flex: "1 1 400px",
  },
  infoSection: {
    flex: "1 1 400px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  input: {
    padding: "0.5rem",
    fontSize: "1rem",
    borderRadius: "4px",
    border: "1px solid #bdc3c7",
  },
  textarea: {
    padding: "0.5rem",
    fontSize: "1rem",
    borderRadius: "4px",
    border: "1px solid #bdc3c7",
    minHeight: "150px",
  },
  button: {
    padding: "0.5rem 1rem",
    fontSize: "1rem",
    backgroundColor: "#1b1b1b",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  infoItem: {
    marginBottom: "1rem",
  },
  map: {
    width: "100%",
    height: "300px",
    border: "none",
    borderRadius: "4px",
    marginTop: "1rem",
  },
  errorMessage: {
    color: "#e74c3c",
    marginTop: "0.5rem",
  },
  successMessage: {
    color: "#2ecc71",
    marginTop: "0.5rem",
  },
};

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");

    try {
      const docRef = await addDoc(collection(db, "contactSubmissions"), {
        ...formData,
        timestamp: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      setSuccess("Mesajınız gönderildi. En kısa sürede size dönüş yapacağız.");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error adding document: ", error);
      setError(
        "Mesajınız gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.contact}>
      <header style={styles.header}>
        <h1 style={styles.title}>İletişim</h1>
        <p style={styles.subtitle}>Bize ulaşın, sorularınızı yanıtlayalım</p>
      </header>

      <div style={styles.content}>
        <section style={styles.formSection}>
          <h2>Bize Mesaj Gönderin</h2>
          <form style={styles.form} onSubmit={handleSubmit}>
            <input
              style={styles.input}
              type="text"
              name="name"
              placeholder="Adınız"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              style={styles.input}
              type="email"
              name="email"
              placeholder="E-posta Adresiniz"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              style={styles.input}
              type="text"
              name="subject"
              placeholder="Konu"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              style={styles.textarea}
              name="message"
              placeholder="Mesajınız"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button style={styles.button} type="submit" disabled={isLoading}>
              {isLoading ? "Gönderiliyor..." : "Gönder"}
            </button>
            {error && <p style={styles.errorMessage}>{error}</p>}
            {success && <p style={styles.successMessage}>{success}</p>}
          </form>
        </section>

        <section style={styles.infoSection}>
          <h2>İletişim Bilgilerimiz</h2>
          <div style={styles.infoItem}>
            <h3>Adres</h3>
            <p>
              Karşıyaka Mah. Kavakdere Cad. Bağlariçi Sokak No:15 Keçiören /
              Ankara
            </p>
          </div>
          <div style={styles.infoItem}>
            <h3>Telefon</h3>
            <p>+90 (546) 229 94 24</p>
          </div>
          <div style={styles.infoItem}>
            <h3>E-posta</h3>
            <p>siparis@fatmabaci.com</p>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d195360.4463604256!2d32.65550394808657!3d40.08640827352045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4082097d856a2c3f%3A0x8d22ac4346722e44!2zS2XDp2nDtnJlbi9BbmthcmE!5e0!3m2!1sen!2str!4v1721421315037!5m2!1sen!2str"
            style={styles.map}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Fatma Bacı Turşuları Konum"
          ></iframe>
        </section>
      </div>
    </div>
  );
}

export default Contact;
